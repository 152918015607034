import React, {useEffect, useState} from "react";
import {hasPermission, Permissions} from "../../../helpers/accessControl";
import {getSurveyById} from "../../../reduxStore/surveySlices/surveySlices";
import {groupQuestionsBySegmentIndex, isUnauthorized, trim} from "../../../helpers/utils";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import UserInfo from "../../layout/UserInfo";
import {Loader} from "../../layout/Loader";
import {IoIosArrowForward} from "react-icons/io";
import {Card, Col, Row} from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import {getAssignedStrategy} from "../../../reduxStore/strategySlice/strategySlice";
import {resetReducers} from "../../../reduxStore/globalKeysSlice/globalKeysSlice";
import { Marks, QuestionType} from "../../../helpers/constants";

const ViewSurvey = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {surveyId} = useParams();
    const orgPreferenceColor = localStorage.getItem("color") !== null && localStorage.getItem("color") !== "undefined" && localStorage.getItem("color") !== undefined ? localStorage.getItem("color") : "#fc6620"

    const [activeQuestionInfo, setActiveQuestionInfo] = useState({
        index: null,
        question: null
    })
    const [showStrategy, setShowStrategy] = useState(false)
    const [questionsList, setQuestionsList] = useState([])

    const surveyApi = useSelector(state => state.survey.getSurveyByIdDataReducer)
    const assignedStrategyApi = useSelector(state => state.strategy.getAssignedStrategyReducer)

    useEffect(() => {
        if (hasPermission([Permissions.REFLECTION_READ], true)) {
            dispatch(getSurveyById(surveyId)).then((res) => {
                if (isUnauthorized(res)) {
                    navigate("/")
                }
            })
        }
    }, [])
    useEffect(() => {
        dispatch(getAssignedStrategy(surveyId)).then((res) => {
            if (isUnauthorized(res)) {
                navigate("/")
            }
        })
        return () => {
            dispatch(resetReducers({sliceNames: ["getAssignedStrategyReducer"]}))
        }
    }, [])
    useEffect(() => {
        if (!surveyApi?.loading || surveyApi?.data !== undefined || surveyApi.data !== null) {
            if (surveyApi?.data?.segmentType === "LINEAR") {
                setQuestionsList(surveyApi?.data?.questions)
                setActiveQuestionInfo({
                    index: 0,
                    question: surveyApi?.data?.questions[0]
                })
            }
            if (surveyApi?.data?.segmentType === "TABULAR") {
                const segmentQuestion = groupQuestionsBySegmentIndex(surveyApi?.data?.segments, surveyApi?.data?.questions)
                setQuestionsList(segmentQuestion)
                setActiveQuestionInfo({
                    index: {
                        segmentIndex: 0,
                        questionIndex: 0
                    },
                    question: segmentQuestion?.[0]?.questionsList[0]
                })
            }
        }
    }, [surveyApi])

    const handleSetActiveQuestion = (questionIndex, segmentIndex) => {
        if (surveyApi?.data?.segmentType === "LINEAR") {
            setActiveQuestionInfo({
                index: questionIndex,
                question: questionsList[questionIndex]
            })
        }
        if (surveyApi?.data?.segmentType === "TABULAR") {
            setActiveQuestionInfo({
                index: {
                    segmentIndex: segmentIndex,
                    questionIndex: questionIndex
                },
                question: questionsList?.[segmentIndex]?.questionsList[questionIndex]
            })
        }
    }

    return (surveyApi?.loading || surveyApi?.data === undefined || surveyApi.data === null) ?
        <Loader/> :
        (
            <section className={"reflection_wrapper"}>
                <div className="cmn_header">
                    <h2 className="mediumFontSize  org-heading m-0">View Reflection </h2> <UserInfo/>
                </div>
                <div className='save-survey-outer main_content '>
                    <div className={"survey-title-container  pt-3 mb-3"}>
                        <h3 className={` mediumFontSize `} title={surveyApi?.data?.name}>{surveyApi?.data?.name}</h3>
                    </div>
                    <div className={"survey-tag-outer w-100 mt-2 me-1 gray_bg_color survey-tag-outer1"}>
                        {
                            surveyApi?.data?.tags?.length > 0 ?
                                <>
                                    {
                                        surveyApi?.data?.tags?.map((tag, index) => {
                                            return (
                                                <div key={index} className={"survey-tags"}>
                                                    <div className={"tag-text"}
                                                         title={tag}>{tag}
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                </>
                                :
                                <div className={"p-1 mediumFontSize font-size-14"}>No Tags Defined</div>

                        }
                    </div>
                    {
                        !assignedStrategyApi?.loading && assignedStrategyApi?.data !== null && assignedStrategyApi?.data !== undefined &&
                        <div className={"strategy-outer mt-2"}>
                            <div
                                className={"strategy-txt cursor-pointer " + (showStrategy ? " strategy-txt-border-radius" : "")}
                                style={{background: orgPreferenceColor}}
                                onClick={() => {
                                    setShowStrategy(!showStrategy)
                                }}>
                                <IoIosArrowForward className={showStrategy ? " rotate-90deg me-1" : " mb-1  "}/>
                                {assignedStrategyApi?.data?.name}
                            </div>
                            {
                                showStrategy &&
                                <div className={"strategy-detail"}>
                                    <div className="form-group mt-1">
                                        <div className='mediumFontSize font-weight-500 font-size-15'>Description</div>
                                        <pre
                                            className={"view-description mediumFontSize font-size-14 opacity-1"}>{assignedStrategyApi?.data?.description}</pre>
                                    </div>
                                    <div className={"form-group mt-1  mb-2"}>
                                        <div className={"mediumFontSize font-weight-500 font-size-15"}> Goals</div>
                                        <div className={"survey-tag-outer w-100 survey-tag-outer1"}>
                                            {
                                                assignedStrategyApi?.data?.goals?.length > 0 ?
                                                    <>
                                                        {
                                                            assignedStrategyApi?.data?.goals?.map((goal, index) => {
                                                                return (
                                                                    <div key={index} className={"survey-tags"}>
                                                                        <div className={"tag-text mediumFontSize"}
                                                                             title={goal}>{goal}</div>
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </>
                                                    :
                                                    <div className={"p-1 mediumFontSize font-size-14 opacity-7"}>No
                                                        Goals Defined</div>
                                            }

                                        </div>

                                    </div>
                                </div>
                            }

                        </div>
                    }
                </div>

                <div className={"mx-2"}>
                    <>
                        <Row className={"m-0"}>
                            <Col lg={6} md={4} className={"pe-0"}>
                                <div className="w-100 mt-2">
                                    <div
                                        className={`question_tabs_wrapper question_tab_container p-3 ${questionsList?.length === 0 ? " display-grid align-items-center" : " "}`}>
                                        {
                                            questionsList?.length === 0 ?
                                                <h6 className={"mediumFontSize text-center "}>Currently no questions
                                                    added to the list</h6>
                                                :
                                                surveyApi?.data?.segmentType === "LINEAR" ?
                                                    questionsList?.map((cur, index) => (
                                                        <div>
                                                            <Nav.Item>
                                                                <Nav.Link
                                                                    className={activeQuestionInfo?.index === index ? !localStorage.getItem("color") ? "" : "active-link" : ""}
                                                                    style={{background: orgPreferenceColor}}
                                                                    onClick={() => handleSetActiveQuestion(index)}
                                                                >
                                                                    <span className='question_count'>{index + 1}</span>
                                                                    <span
                                                                        className={"question_name"}>{cur.questionText}</span>
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                        </div>
                                                    ))
                                                    :
                                                    questionsList?.map((segment, segmentIndex) => (
                                                        <div className={"position-relative mb-3 "}>
                                                            <div
                                                                className={`section_title_outer ${segmentIndex === 0 ? "mt-0" : "mt-5"}`}>
                                                                <div className={"add_section_container"}>
                                                                    <span
                                                                        className={`mediumFontSize font-weight-500 font-size-15`}>{segment?.segmentName || "Section " + (segmentIndex + 1)}</span>
                                                                </div>
                                                                {
                                                                    segment?.questionsList?.length === 0 ?
                                                                        <div
                                                                            className={"mediumFontSize text-center font-size-14 m-2"}>Currently,
                                                                            no questions are added</div>
                                                                        :
                                                                        segment?.questionsList?.map((cur, index) => (
                                                                            <Nav.Item>
                                                                                <Nav.Link
                                                                                    className={ activeQuestionInfo?.index?.segmentIndex===segmentIndex && activeQuestionInfo?.index?.questionIndex===index ? !localStorage.getItem("color") ? "" : "active-link" : ""}
                                                                                    style={{background: orgPreferenceColor}}
                                                                                    onClick={() => handleSetActiveQuestion(index, segmentIndex)}
                                                                                >
                                                                                    <span
                                                                                        className='question_count'>{index + 1}</span>
                                                                                    <span
                                                                                        className={"question_name"}>{cur.questionText}</span>
                                                                                </Nav.Link>
                                                                            </Nav.Item>
                                                                        ))
                                                                }
                                                            </div>
                                                        </div>
                                                    ))
                                        }

                                    </div>

                                </div>

                            </Col>
                            <Col lg={6} md={8} className={"pe-1"}>

                                <div className='SurveyName_container'>
                                    <div className={"details_wrapper details_container_outer"}>
                                        <div className={"d-flex"}>
                                            <Card className={'question-card question-list mt-2 cmn_radius'}>
                                                <Card.Body>

                                                    <div className={"mt-2"}>
                                                        <select
                                                            disabled={true}
                                                            value={activeQuestionInfo?.question?.type}
                                                            name={"type"}
                                                            className={"question-type-dropdown gray_bg_color on_focus box-shadow-unset-on-focus"}>
                                                            <option value={QuestionType.OPTION}>Option</option>
                                                            <option value={QuestionType.TEXT}>Text</option>
                                                            <option value={QuestionType.NUMBER}>Number</option>
                                                        </select>
                                                    </div>
                                                    <div className="question-dropdown-outer mt-2">
                                                        <input
                                                            type={"text"}
                                                            value={activeQuestionInfo?.question?.questionText}
                                                            name={"questionText"}
                                                            disabled={true}
                                                            className={"form-control cmn_fontStyle question-txt-input border-gray-on-focus gray_bg_color on_focus box-shadow-unset-on-focus"}
                                                            placeholder='Enter Question'
                                                        ></input>
                                                    </div>
                                                    {
                                                        activeQuestionInfo?.question?.type === QuestionType.NUMBER &&
                                                        <div className={"number-range-value-outer"}>
                                                            <div className={"mt-2 min-value-container"}>
                                                                <div className={"max-value-heading "}>Min :</div>
                                                                <input type={"number"}
                                                                       inputMode={"numeric"}
                                                                       name={"min"}
                                                                       disabled={true}
                                                                       value={activeQuestionInfo?.question?.number?.min}
                                                                       placeholder={"0"}
                                                                       className={"form-control gray_bg_color on_focus range-value-input me-auto"}/>
                                                                <div className={"me-2 good-bad-outer"}>
                                                                    <div
                                                                        className={activeQuestionInfo?.question?.number?.isMinLowestRank ? "color-data-high-risk" : "color-data-good"}/>
                                                                    <select
                                                                        name={"isMinLowestRank"}
                                                                        value={activeQuestionInfo?.question?.number?.isMinLowestRank}
                                                                        className={"good-bad-dropdown"}
                                                                        disabled={true}
                                                                    >
                                                                        <option value={"true"}
                                                                                selected={!activeQuestionInfo?.question?.number?.isMinLowestRank}>{Marks.HIGH_RISK}</option>
                                                                        <option value={"false"}
                                                                                selected={activeQuestionInfo?.question?.number?.isMinLowestRank}>{Marks.GOOD}</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className={"mt-2 max-value-container"}>
                                                                <div className={"max-value-heading "}>Max :</div>
                                                                <input type={"number"}
                                                                       disabled={true}
                                                                       inputMode={"numeric"}
                                                                       name={"max"}
                                                                       value={activeQuestionInfo?.question?.number?.max}
                                                                       placeholder={"..."}
                                                                       className={"form-control gray_bg_color on_focus range-value-input me-auto"}/>
                                                                <div className={"me-2 good-bad-outer"}>
                                                                    <div
                                                                        className={activeQuestionInfo?.question?.number?.isMinLowestRank ? "color-data-good" : "color-data-high-risk"}/>
                                                                    <select
                                                                        disabled={true}
                                                                        value={activeQuestionInfo?.question?.number?.isMinLowestRank}
                                                                        name={"isMinLowestRank"}
                                                                        className={"good-bad-dropdown"}>
                                                                        <option value={"false"}
                                                                                selected={!activeQuestionInfo?.question?.number?.isMinLowestRank}>{Marks.HIGH_RISK}</option>
                                                                        <option value={"true"}
                                                                                selected={activeQuestionInfo?.question?.number?.isMinLowestRank}>{Marks.GOOD}</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        activeQuestionInfo?.question?.type === QuestionType.OPTION &&
                                                        <div className={"options-list-container mt-2"}>
                                                            {
                                                                [...Array(activeQuestionInfo?.question?.options?.length)].map((x, i) => {

                                                                    return (
                                                                        <div key={i}>
                                                                            <div
                                                                                className={"option-select-container mt-2 position-relative"}>
                                                                                <div
                                                                                    className={"option-container option_Wrapper align-items-center"}>
                                                                                    <input
                                                                                        type={"text"}
                                                                                        name={"optionText"}
                                                                                        disabled={true}
                                                                                        value={activeQuestionInfo?.question?.options[i]?.optionText}
                                                                                        className={"form-control gray_bg_color box-shadow-unset-on-focus border-gray-on-focus on_focus option-input"}
                                                                                        placeholder={"Option " + (i + 1)}/>

                                                                                    <div
                                                                                        className={"risk-color-and-dropdown"}>
                                                                                        <input
                                                                                            disabled={true}
                                                                                            placeholder={"Enter weight here..."}
                                                                                            value={activeQuestionInfo?.question?.options[i]?.optionValue}
                                                                                            className={"form-control border-gray-on-focus gray_bg_color on_focus box-shadow-unset-on-focus"}
                                                                                            type={"number"}
                                                                                            min={1}
                                                                                            name={"optionValue"}
                                                                                        />
                                                                                    </div>


                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    }
                                                    <div
                                                        className={`tag-outer mt-3 mb-2 material-icons gray_bg_color focus-within-bg-white `}>
                                                        {
                                                            activeQuestionInfo?.question?.tags?.length > 0 ?
                                                                <>
                                                                    {
                                                                        activeQuestionInfo?.question?.tags?.map((tag, tagIndex) => {
                                                                            return (
                                                                                <div className={"tags"} key={tagIndex}>
                                                                                    <div className={"tag-text"}
                                                                                         title={"tag"}>{trim(tag, 12)}</div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </> :
                                                                <div className={"p-1 mediumFontSize font-size-14"}>No Tags Defined</div>
                                                        }

                                                    </div>
                                                    <label className={"required-label"}>
                                                        <input name={"isRequired"}
                                                               disabled={true}
                                                               checked={activeQuestionInfo?.question?.isRequired}
                                                               className={"required-checkbox"}
                                                               type="checkbox"/>
                                                        Marked as required
                                                    </label>

                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </div>


                                </div>

                            </Col>
                        </Row>
                    </>
                </div>


            </section>
        );
}
export default ViewSurvey