import Modal from 'react-bootstrap/Modal';
import {convertScheduledDate, convertScheduledTime, formatAndComputeAutoDateRange, toString} from "../../helpers/utils";
import {allTimezones, useTimezoneSelect} from "react-timezone-select";
import React from "react";

function ViewCampaignModal({campaignData, show, setShow}) {

    const labelStyle = "altName"
    const timezones = {
        ...allTimezones
    }
    const {options, parseTimezone} = useTimezoneSelect({labelStyle, timezones})
    const handleClose = () => setShow(false);

    return (
        <>
            <Modal size={"xl"} modal-lg centered show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{campaignData.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body className={"modal_outer"}>
                    <div>
                        <div className="row main_content">
                            <div className={"col-md-12 p-0"}>
                                <section className=" create_campaign_inner_content pt-2 pb-2  sub-org-container-box">
                                    <div className="row">
                                        <div className="col-lg-6 col-sm-12 col-md-12">
                                            <div className="">
                                                <h5 className="cmn_sub_heading mediumFontSize">Campaign Info</h5>
                                                <div className="form_group form_content_outer mb-2">
                                                    <label>*Campaign Name</label>
                                                    <div className={"campaign_input_field"}>
                                                        {campaignData.name}
                                                    </div>
                                                </div>
                                                <div className="form_group form_content_outer mb-2">
                                                    <label>*Campaign Type</label>
                                                    <div className={"campaign_input_field"}>
                                                        {toString(campaignData.type)}
                                                    </div>
                                                </div>

                                                <div className="form_group form_content_outer mb-2">
                                                    <label>*Selected {toString(campaignData.type)} </label>
                                                    <div className={"campaign_input_field"}>
                                                        {toString(campaignData.relationalName)}
                                                    </div>

                                                </div>


                                                <h5 className="cmn_sub_heading">Scheduling</h5>

                                                <div className="form_group form_content_outer mb-2">
                                                    <div className="d-flex schedule_wrapper_box">
                                                        <div className="flex-grow-1">
                                                            <label>Select Date and Time </label>
                                                            <div className="d-flex gap-2 datetime_wrapper">
                                                                <div className={"campaign_input_field"}>
                                                                    {formatAndComputeAutoDateRange(convertScheduledDate(campaignData.scheduledOn, campaignData.selectedTimeZone), "", "yyyy-MM-dd").startDate}
                                                                </div>
                                                                <div className={"campaign_input_field"}>
                                                                    {convertScheduledTime(campaignData.scheduledOn, campaignData.selectedTimeZone)}
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div className="form_group form_content_outer mb-2 flex-grow-1">
                                                            <label>Recurrence</label>
                                                            <div className={"campaign_input_field"}>
                                                                {toString(campaignData.recurrence)}
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="form_group form_content_outer mb-2">
                                                    <label>Select time zone </label>
                                                    <div className={"campaign_input_field"}>
                                                        {parseTimezone(campaignData.selectedTimeZone).label}
                                                    </div>


                                                </div>


                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-sm-12 col-md-12">
                                            <div className="">
                                                <h5 className="cmn_sub_heading mediumFontSize">Message</h5>

                                                <div className="form_group form_content_outer mb-2">
                                                    <label>Recipients </label>
                                                    <div className={"campaign_input_field"}>
                                                        {
                                                            campaignData?.recipients?.map((recipient, index) => {
                                                                return (
                                                                    <div key={index} className={"campaign-recipients"}>
                                                                        <div className={"tag-text"}
                                                                             title={recipient}>{recipient}</div>
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>


                                                </div>
                                                <div className="form_group form_content_outer mb-2">
                                                    <label>Subject </label>
                                                    <div className={"campaign_input_field"}>
                                                        {campaignData.subject}
                                                    </div>


                                                </div>
                                                <div className="form_group form_content_outer mb-2">
                                                    <label>Message </label>
                                                    <div className={"campaign_input_field campaign-message"}
                                                         dangerouslySetInnerHTML={{__html: campaignData.message}}
                                                    >
                                                    </div>
                                                </div>


                                            </div>
                                        </div>

                                    </div>

                                </section>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className={"modal_footer"}>
                    <button className="modal_cancel_btn cmn_modal_btn" onClick={handleClose}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ViewCampaignModal;
